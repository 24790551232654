import React from 'react';

const AnimatedChart: React.FC = () => {
  const barColors = ['#4CAF50', '#2196F3', '#FFC107', '#E91E63'];

  return (
    <svg
      viewBox="0 0 400 200"
      className="w-full h-auto"
      style={{
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      }}
    >
      <defs>
        {barColors.map((color, index) => (
          <linearGradient key={index} id={`barGradient${index}`} x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={color} stopOpacity="0.8" />
            <stop offset="100%" stopColor={color} stopOpacity="0.2" />
          </linearGradient>
        ))}
      </defs>
      {[
        { height: 120, x: 20 },
        { height: 160, x: 120 },
        { height: 100, x: 220 },
        { height: 180, x: 320 },
      ].map((bar, index) => (
        <g key={index}>
          <rect
            x={bar.x}
            y={200 - bar.height}
            width="60"
            height={bar.height}
            fill={`url(#barGradient${index})`}
            className={`animate-bar-${index + 1}`}
          />
          <circle
            cx={bar.x + 30}
            cy={200 - bar.height}
            r="5"
            fill={barColors[index]}
            className={`animate-dot-${index + 1}`}
          />
        </g>
      ))}
    </svg>
  );
};

export default AnimatedChart;