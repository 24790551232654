import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';

const BlogPage: React.FC = () => {
  const { darkMode } = useDarkMode();

  return (
    <div className={`container mx-auto px-4 py-8 mt-16 ${darkMode ? 'dark' : ''}`}>
      <article className="max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
        <header className="p-8 bg-gradient-to-r from-blue-500 to-purple-600 text-white">
          <h1 className="text-4xl font-bold mb-4">Five Use Cases for AI in B2B Marketing (Beyond Content Generation)</h1>
          <div className="flex items-center mb-4">
            <img 
              src="/dev-ganesan.jpg" 
              alt="Dev Ganesan" 
              className="w-16 h-16 rounded-full mr-4 object-cover border-2 border-white"
            />
            <div>
              <p className="text-lg font-semibold">Dev Ganesan</p>
              <p className="text-sm opacity-75">Author</p>
            </div>
          </div>
          <p className="text-sm opacity-75">Published on October 8, 2024</p>
        </header>
        
        <div className="p-8 prose dark:prose-invert max-w-none">
          <p className="lead text-xl mb-6">
            The landscape of marketing and technology has experienced a seismic shift over the past three decades. While AI's potential in marketing is vast, many are only scratching the surface with content generation. Let's explore five advanced use cases that go beyond creating content.
          </p>

          <a 
            href="https://www.marketingprofs.com/articles/2024/52137/ai-use-cases-for-b2b-marketing" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 dark:text-blue-400 hover:underline mb-8 inline-block"
          >
            Read the original article
          </a>

          <h2 className="text-2xl font-bold mt-8 mb-4">1. Content Tagging</h2>
          <p>AI excels at processing vast amounts of information, making it perfect for content tagging and categorization.</p>
          <ul>
            <li>Analyzes context, tone, and subject matter across multiple dimensions</li>
            <li>Combines marketer knowledge with AI-assisted tagging for quality recommendations</li>
            <li>Improves content findability and reporting capabilities</li>
          </ul>

          <blockquote className="border-l-4 border-blue-500 pl-4 italic my-6">
            "AI doesn't just tag content faster; it can do so with a level of consistency and depth that's simply unattainable through manual processes."
          </blockquote>

          <h2 className="text-2xl font-bold mt-8 mb-4">2. Dynamic Personalization</h2>
          <p>AI enables real-time adaptation of digital experiences tailored to each visitor.</p>
          <ul>
            <li>Synthesizes demographic, firmographic, behavioral, and historical data</li>
            <li>Delivers truly personalized experiences at scale</li>
            <li>Improves user experience and increases conversion rates</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">3. Intent-Signal Amplification</h2>
          <p>AI helps identify the 5-10% of your TAM that's ready to buy right now.</p>
          <ul>
            <li>Analyzes first-party and third-party data for buying signals</li>
            <li>Recommends next best actions for high-intent prospects</li>
            <li>Guides outbound efforts with precision</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">4. Buying Agents</h2>
          <p>AI-powered assistants cater to the 75% of B2B buyers who prefer a rep-free sales experience.</p>
          <ul>
            <li>Acts as 24/7 virtual buyer assistants</li>
            <li>Understands complex queries and provides product information</li>
            <li>Guides prospects through initial sales stages</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">5. Revenue Intelligence</h2>
          <p>AI provides unprecedented insights into how content influences buying decisions.</p>
          <ul>
            <li>Analyzes patterns in content consumption and pipeline progression</li>
            <li>Identifies which content truly moves the needle in sales processes</li>
            <li>Enables real-time tuning of marketing and content strategies</li>
          </ul>

          <h2 className="text-2xl font-bold mt-8 mb-4">Conclusion</h2>
          <p>
            AI's potential in B2B marketing extends far beyond content generation. By leveraging these advanced use cases, marketers can deliver the right content to the right buyer at precisely the right moment, all at an unprecedented scale.
          </p>
        </div>
      </article>
    </div>
  );
};

export default BlogPage;