import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, User, Users, BarChart, Newspaper } from 'lucide-react';
import Modal from './Modal';

const useCases = [
  {
    title: 'Individual Investors',
    description: 'Make informed decisions with AI-powered insights tailored for personal portfolios.',
    icon: User,
    color: '#FFA500', // Changed to orange
    details: [
      { title: 'Market Trends', description: 'Gain insights into market movements for confident decision-making.' },
      { title: 'Personalized Analysis', description: 'Get insights tailored to your portfolio and goals.' },
      { title: 'Actionable Guidance', description: 'Navigate the financial landscape with clear, simple analysis.' },
    ],
  },
  {
    title: 'Professional & Retail Traders',
    description: 'Gain a competitive edge with real-time analysis and automated trading narratives.',
    icon: Users,
    color: '#4169E1', // Royal Blue
    details: [
      { title: 'Real-Time Edge', description: 'Identify trading opportunities through real-time monitoring.' },
      { title: 'Enhanced Decisions', description: 'Use advanced data insights for better trading strategies.' },
      { title: 'Customized Insights', description: 'Tailored analyses for entry and exit points.' },
      { title: 'Situational Awareness', description: 'Granular view of market activity for specific periods.' },
    ],
  },
  {
    title: 'Financial Analysts',
    description: 'Enhance research capabilities and generate comprehensive reports efficiently.',
    icon: BarChart,
    color: '#228B22', // Forest Green
    details: [
      { title: 'Streamlined Workflow', description: 'Automate the analysis of large data volumes.' },
      { title: 'Focused Insights', description: 'Concentrate on the most critical information.' },
      { title: 'Effective Communication', description: 'Deliver well-structured, easy-to-understand reports.' },
    ],
  },
  {
    title: 'Media Professionals',
    description: 'Create data-driven financial news stories with automated insights and narratives.',
    icon: Newspaper,
    color: '#FF6347', // Tomato
    details: [
      { title: 'Targeted Insights', description: 'Choose market sectors or trends for ready-to-use insights.' },
      { title: 'Stay Informed', description: 'Access real-time market developments with added context.' },
      { title: 'Content Enrichment', description: 'Use in-depth analysis to provide richer content for audiences.' },
    ],
  },
];

const UseCasesSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalContent, setModalContent] = useState<{ title: string; content: React.ReactNode; color: string } | null>(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % useCases.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + useCases.length) % useCases.length);
  };

  const openModal = (useCase: typeof useCases[0]) => {
    setModalContent({
      title: useCase.title,
      content: (
        <div>
          {useCase.details.map((detail, index) => (
            <div key={index} className="mb-4">
              <h4 className="font-semibold text-lg">{detail.title}</h4>
              <p className="text-gray-600 dark:text-gray-300">{detail.description}</p>
            </div>
          ))}
        </div>
      ),
      color: useCase.color,
    });
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <section id="use-cases" className="py-20 bg-gray-100 dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 dark:text-white">Use Cases</h2>
        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-300 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {useCases.map((useCase) => (
                <div key={useCase.title} className="w-full flex-shrink-0 px-4">
                  <div className="bg-white dark:bg-gray-700 rounded-lg shadow-md p-6 flex flex-col items-center text-center">
                    <useCase.icon className="w-16 h-16 mb-4" style={{ color: useCase.color }} />
                    <h3 className="text-xl font-semibold mb-2" style={{ color: useCase.color }}>{useCase.title}</h3>
                    <p className="text-gray-600 dark:text-gray-300 mb-4">{useCase.description}</p>
                    <button
                      onClick={() => openModal(useCase)}
                      className="font-semibold focus:outline-none"
                      style={{ color: useCase.color }}
                      aria-label={`Learn more about ${useCase.title}`}
                    >
                      <div className="animate-dots">
                        {[...Array(6)].map((_, i) => (
                          <span key={i} style={{ backgroundColor: useCase.color }}></span>
                        ))}
                      </div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white dark:bg-gray-600 rounded-full p-2 shadow-md"
            aria-label="Previous slide"
          >
            <ChevronLeft className="w-6 h-6 text-gray-600 dark:text-gray-300" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white dark:bg-gray-600 rounded-full p-2 shadow-md"
            aria-label="Next slide"
          >
            <ChevronRight className="w-6 h-6 text-gray-600 dark:text-gray-300" />
          </button>
        </div>
      </div>
      {modalContent && (
        <Modal
          isOpen={!!modalContent}
          onClose={closeModal}
          title={modalContent.title}
          content={modalContent.content}
          color={modalContent.color}
        />
      )}
    </section>
  );
};

export default UseCasesSection;