import React, { useState } from 'react';
import { Target, Lightbulb, Zap } from 'lucide-react';
import { Parallax } from 'react-scroll-parallax';
import Modal from './Modal';

const missionData = [
  {
    title: 'Objective',
    icon: Target,
    color: '#FFA500', // Changed to orange
    summary: 'To revolutionize financial decision-making by providing AI-driven insights and automated narratives.',
    details: [
      { title: 'Empower Decision-Making', description: 'Provide objective, crystal-clear analysis and insights.' },
      { title: 'Groundbreaking AI Tool', description: 'Translate complex market data and triangulated inference into human language.' },
      { title: 'Situational Awareness', description: 'Democratize access to advanced financial insights for everyone.' },
    ],
  },
  {
    title: 'Background',
    icon: Lightbulb,
    color: '#4169E1', // Royal Blue
    summary: 'In a world of complex financial data, we recognized the need for clear, actionable insights for traders and investors.',
    details: [
      { title: 'Empowers All Users', description: 'From individual investors to professional managers with advanced market insights.' },
      { title: 'Advanced Technology', description: 'Uses signal processing, machine learning, and AI to transform complex data into actionable insights.' },
      { title: 'Enhanced Understanding', description: 'Boost situational awareness, simplifying the understanding of market drivers.' },
      { title: 'Democratizes Access', description: 'Levels the playing field by making sophisticated financial analysis accessible to all.' },
    ],
  },
  {
    title: 'Solution',
    icon: Zap,
    color: '#228B22', // Forest Green
    summary: 'TradeInsightAI_ combines advanced AI algorithms with financial expertise to deliver real-time analysis and easy-to-understand narratives.',
    details: [
      { title: 'Advanced Insights', description: 'Powered by cutting-edge technology and deep financial markets domain expertise.' },
      { title: 'Comprehensive Coverage', description: 'Integrates high-resolution financial data with insights from unstructured sources (news, social media, corporate reports).' },
      { title: 'Granular Clarity', description: 'Provides detailed analysis while eliminating unnecessary complexity.' },
      { title: 'In-Depth Insights', description: 'Delivers a richer understanding beyond surface-level information.' },
    ],
  },
];

const OurMission: React.FC = () => {
  const [modalContent, setModalContent] = useState<{ title: string; content: React.ReactNode; color: string } | null>(null);

  const openModal = (item: typeof missionData[0]) => {
    setModalContent({
      title: item.title,
      content: (
        <div>
          {item.details.map((detail, index) => (
            <div key={index} className="mb-4">
              <h4 className="font-semibold text-lg">{detail.title}</h4>
              <p className="text-gray-600 dark:text-gray-300">{detail.description}</p>
            </div>
          ))}
        </div>
      ),
      color: item.color,
    });
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <section id="mission" className="py-12 md:py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <Parallax translateY={[-10, 10]}>
          <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12 text-gray-800 dark:text-white">Mission</h2>
        </Parallax>
        <div className="grid md:grid-cols-3 gap-6 md:gap-8">
          {missionData.map((item, index) => (
            <Parallax key={item.title} translateY={[20, -20]} scale={[0.9, 1.1]} easing="easeInQuad">
              <div
                className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 transition-all duration-300 hover:shadow-lg transform hover:-translate-y-2 hover:scale-105 perspective-1000 group"
                style={{
                  transformStyle: 'preserve-3d',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div className="transition-transform duration-300 group-hover:rotate-y-10 group-hover:rotate-x-10" style={{ transformStyle: 'preserve-3d' }}>
                  <div className="flex items-center mb-3 md:mb-4">
                    <item.icon className="w-6 h-6 md:w-8 md:h-8 mr-2 md:mr-3 transition-transform duration-300 group-hover:scale-110" style={{ color: item.color }} />
                    <h3 className="text-lg md:text-xl font-semibold" style={{ color: item.color }}>{item.title}</h3>
                  </div>
                  <p className="text-sm md:text-base text-gray-600 dark:text-gray-300 mb-3 md:mb-4">{item.summary}</p>
                  <button
                    onClick={() => openModal(item)}
                    className="text-sm md:text-base font-semibold focus:outline-none transition-colors duration-300"
                    style={{ color: item.color }}
                    aria-label={`Learn more about ${item.title}`}
                  >
                    <div className="animate-dots">
                      {[...Array(6)].map((_, i) => (
                        <span key={i} style={{ backgroundColor: item.color }}></span>
                      ))}
                    </div>
                  </button>
                </div>
              </div>
            </Parallax>
          ))}
        </div>
      </div>
      {modalContent && (
        <Modal
          isOpen={!!modalContent}
          onClose={closeModal}
          title={modalContent.title}
          content={modalContent.content}
          color={modalContent.color}
        />
      )}
    </section>
  );
};

export default OurMission;