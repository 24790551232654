import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Parallax } from 'react-scroll-parallax';
import AnimatedChart from './AnimatedChart';
import { Link } from 'react-router-dom';

const HeroSection: React.FC = () => {
  return (
    <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-12 md:py-20 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center">
          <Parallax translateY={[-20, 20]} className="w-full md:w-1/2 mb-8 md:mb-0">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
              Unlock Financial Insights with AI
            </h1>
            <p className="text-lg md:text-xl mb-6 leading-relaxed">
              tradeInsightAI_ delivers real-time, objective, and intelligent market insights. Our AI-powered platform provides triangulated, contextually aware analysis for smarter trading decisions.
            </p>
            <Link to="/contact" className="bg-white text-blue-600 font-semibold py-2 px-4 md:py-3 md:px-6 rounded-full inline-flex items-center transition-all duration-300 hover:bg-blue-100 animate-pulse">
              Click To Contact Us
              <ArrowRight className="ml-2 w-4 h-4 md:w-5 md:h-5" />
            </Link>
          </Parallax>
          <Parallax translateY={[20, -20]} className="w-full md:w-1/2">
            <div className="relative">
              <AnimatedChart />
            </div>
          </Parallax>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;