import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Facebook, Instagram, X } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';

const Footer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const handleScrollToSection = (sectionId: string) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <footer className={`bg-gray-900 ${darkMode ? 'dark:bg-gray-800' : ''} text-white py-8 md:py-12`}>
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex items-center mb-6 md:mb-0">
            <Link to="/" className="flex items-center space-x-2 group">
              <div className="transition-transform duration-300 ease-in-out transform group-hover:scale-110 group-hover:rotate-3">
                <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-9 h-9 md:w-10 md:h-10">
                  <rect x="4" y="4" width="4" height="24" fill="currentColor"/>
                  <rect x="12" y="12" width="4" height="20" fill="currentColor"/>
                  <rect x="20" y="8" width="4" height="20" fill="currentColor"/>
                  <rect x="28" y="0" width="4" height="18" fill="currentColor"/>
                </svg>
              </div>
              <span className="text-xl md:text-2xl font-bold text-green-400 transition-transform duration-300 ease-in-out transform group-hover:scale-105">tradeInsightAI_</span>
            </Link>
          </div>
          <nav className="mb-6 md:mb-0">
            <ul className="flex space-x-4 md:space-x-6">
              <li>
                <Link
                  to="/#mission"
                  onClick={() => handleScrollToSection('mission')}
                  className="text-sm md:text-base hover:text-green-400 transition-colors"
                >
                  Mission
                </Link>
              </li>
              <li>
                <Link
                  to="/#features"
                  onClick={() => handleScrollToSection('features')}
                  className="text-sm md:text-base hover:text-green-400 transition-colors"
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  to="/#use-cases"
                  onClick={() => handleScrollToSection('use-cases')}
                  className="text-sm md:text-base hover:text-green-400 transition-colors"
                >
                  Use Cases
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-sm md:text-base hover:text-green-400 transition-colors">
                  Blog
                </Link>
              </li>
            </ul>
          </nav>
          <div className="flex space-x-4">
            <a href="#" className="hover:text-green-400 transition-colors duration-300" aria-label="Facebook">
              <Facebook className="w-6 h-6 md:w-7 md:h-7" />
            </a>
            <a href="#" className="hover:text-green-400 transition-colors duration-300" aria-label="Instagram">
              <Instagram className="w-6 h-6 md:w-7 md:h-7" />
            </a>
            <a href="#" className="hover:text-green-400 transition-colors duration-300" aria-label="X (formerly Twitter)">
              <X className="w-6 h-6 md:w-7 md:h-7" />
            </a>
            <a href="#" className="hover:text-green-400 transition-colors duration-300" aria-label="TikTok">
              <svg className="w-6 h-6 md:w-7 md:h-7" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-6 md:mt-8 text-center text-gray-400 text-xs md:text-sm">
          © {new Date().getFullYear()} tradeInsightAI_. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;