import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { DarkModeProvider, useDarkMode } from './contexts/DarkModeContext';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import OurMission from './components/OurMission';
import FeaturesSection from './components/FeaturesSection';
import UseCasesSection from './components/UseCasesSection';
import FAQSection from './components/FAQSection';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import ContactPage from './components/ContactPage';

console.log('App component is being rendered');

function AppContent() {
  const { darkMode } = useDarkMode();
  console.log('Current dark mode state:', darkMode);

  return (
    <Router>
      <ParallaxProvider>
        <div className={`min-h-screen ${darkMode ? 'dark' : ''}`}>
          <div className="bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-300">
            <Header />
            <Routes>
              <Route path="/" element={
                <main className="pt-16 md:pt-20">
                  <HeroSection />
                  <OurMission />
                  <FeaturesSection />
                  <UseCasesSection />
                  <FAQSection />
                </main>
              } />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/admin" element={<AdminLogin />} />
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
            <Footer />
          </div>
        </div>
      </ParallaxProvider>
    </Router>
  );
}

function App() {
  return (
    <DarkModeProvider>
      <AppContent />
    </DarkModeProvider>
  );
}

export default App;