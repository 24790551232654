import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode;
  color: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, content, color }) => {
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'visible';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4">
      <div className={`bg-white dark:bg-gray-800 rounded-lg p-8 max-w-md w-full mx-4 shadow-2xl transform transition-all duration-300 ease-in-out animate-fadeIn ${darkMode ? 'text-gray-100' : 'text-gray-800'}`}>
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-2xl font-semibold animate-float" style={{ color: color }}>{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-300 focus:outline-none"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="mt-4 max-h-[60vh] overflow-y-auto pr-4 space-y-4">
          {React.Children.map(content as React.ReactElement[], (child, index) => (
            <div className="animate-fadeIn" style={{ animationDelay: `${index * 0.1}s` }}>
              {React.cloneElement(child, {
                className: `${child.props.className || ''} ${darkMode ? 'text-gray-300' : 'text-gray-600'}`
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;