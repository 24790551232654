console.log('main.tsx is being executed');

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { DarkModeProvider } from './contexts/DarkModeContext';

console.log('Imports completed');

class ErrorBoundary extends React.Component<{children: React.ReactNode}, {hasError: boolean, error: Error | null}> {
  constructor(props: {children: React.ReactNode}) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    console.error("Caught an error in getDerivedStateFromError:", error);
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught in componentDidCatch:");
    console.error("Error:", error);
    console.error("Error Info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong. Please check the console for more details.</h1>
          <p>Error: {this.state.error && this.state.error.toString()}</p>
          <p>If you're not seeing any error messages in the console, please let us know.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

console.log('About to render the app');

try {
  const root = document.getElementById('root');
  console.log('Root element:', root);

  if (!root) {
    throw new Error('Root element not found');
  }

  const reactRoot = ReactDOM.createRoot(root);
  console.log('React root created');

  reactRoot.render(
    <React.StrictMode>
      <ErrorBoundary>
        <DarkModeProvider>
          <App />
        </DarkModeProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
  console.log('App rendered successfully');
} catch (error) {
  console.error('Error during rendering:', error);
  if (error instanceof Error) {
    console.error('Error message:', error.message);
    console.error('Error stack:', error.stack);
  }
}