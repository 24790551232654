import React, { useState, useEffect } from 'react';
import { Send, RefreshCw } from 'lucide-react';

const ContactPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [captcha, setCaptcha] = useState({ question: '', answer: '' });
  const [userCaptcha, setUserCaptcha] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const operations = ['+', '-', '*'];
    const operation = operations[Math.floor(Math.random() * operations.length)];
    let num1, num2, answer, question;

    switch (operation) {
      case '+':
        num1 = Math.floor(Math.random() * 20) + 1;
        num2 = Math.floor(Math.random() * 20) + 1;
        answer = num1 + num2;
        question = `${num1} + ${num2}`;
        break;
      case '-':
        num1 = Math.floor(Math.random() * 20) + 10;
        num2 = Math.floor(Math.random() * 10) + 1;
        answer = num1 - num2;
        question = `${num1} - ${num2}`;
        break;
      case '*':
        num1 = Math.floor(Math.random() * 10) + 1;
        num2 = Math.floor(Math.random() * 5) + 1;
        answer = num1 * num2;
        question = `${num1} × ${num2}`;
        break;
    }

    setCaptcha({ question, answer: answer.toString() });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (userCaptcha !== captcha.answer) {
      setError('Incorrect captcha. Please try again.');
      generateCaptcha();
      setUserCaptcha('');
      return;
    }
    // Here you would typically send the form data to your backend
    // For now, we'll just simulate a successful submission
    setSubmitted(true);
  };

  if (submitted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600 p-4">
        <div className="max-w-lg w-full bg-white rounded-lg shadow-xl p-8 transform transition-all duration-500 ease-in-out hover:scale-105">
          <h2 className="text-3xl font-bold text-center mb-4 text-blue-600">Thank You!</h2>
          <p className="text-center text-gray-700 text-lg">Your message has been received. We'll get back to you soon.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600 p-4">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-xl p-8 transform transition-all duration-500 ease-in-out hover:scale-105">
        <h2 className="text-3xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              required
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              rows={4}
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="captcha" className="block text-gray-700 font-semibold mb-2">
              Captcha: What is {captcha.question}?
            </label>
            <div className="flex">
              <input
                type="text"
                id="captcha"
                value={userCaptcha}
                onChange={(e) => setUserCaptcha(e.target.value)}
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                required
              />
              <button
                type="button"
                onClick={generateCaptcha}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-r-md hover:bg-gray-300 transition-all duration-300"
                aria-label="Refresh Captcha"
              >
                <RefreshCw className="w-5 h-5" />
              </button>
            </div>
          </div>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold py-3 px-4 rounded-md hover:from-blue-700 hover:to-purple-700 transition-all duration-300 flex items-center justify-center"
          >
            Send Message
            <Send className="ml-2 w-5 h-5" />
          </button>
        </form>
        <p className="mt-6 text-center text-gray-600">
          Or email us directly at{' '}
          <a href="mailto:info@tradeinsight.ai" className="text-blue-600 hover:underline">
            info@tradeinsight.ai
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactPage;