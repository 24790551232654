import React, { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "What does tradeInsightAI provide?",
    answer: "A groundbreaking AI tool that transforms complex financial data into human language, offering objective situational awareness and democratizing trading insights."
  },
  {
    question: "How does tradeInsightAI help users understand complex financial markets?",
    answer: "We use advanced signal processing, machine learning, and bespoke generative AI to transform complex financial data—including time series, news, social media sentiment, and economic events—into clear, actionable narratives. By modeling the double auction process with ultra high-resolution data, we provide deep insights into ongoing market dynamics, replacing cluttered price charts and indicators, which can often overwhelm, with straightforward narratives in human language. Our holistic approach integrates high-frequency data with real-time insights from diverse sources, giving users sophisticated situational awareness and enabling them to understand market drivers and operate in the markets with greater confidence. Our goal is to make advanced financial insights accessible to everyone, from casual investors to seasoned professionals."
  },
  {
    question: "Why use tradeinsightAI?",
    answer: "• Disciplined Decision-Making: tradeInsightAI simplifies complex data into plain language, enabling clear and unbiased decisions for any investor.\n• Emotion Check: Offers an objective perspective, minimizing emotional biases and supporting smarter, data-driven choices.\n• Comprehensive Insights: Integrates data from various sources to give a holistic view of market dynamics in real-time.\n• Situational Awareness: Provides timely insights, allowing you to react quickly to market changes and opportunities.\n• Actionable Narratives: Delivers plain English explanations instead of complex charts, making insights easy to understand and act upon.\n• Leveling the Playing Field: Empowers individual investors with professional-grade insights, democratizing advanced financial analysis.\n• Efficiency: Automates data analysis, saving you time and delivering reliable, actionable insights for fast, objective decision-making."
  },
  {
    question: "What's tradeinsightAI's team background?",
    answer: "tradeInsightAI was founded by a pioneer in financial technology who was an early adopter of neural networks for trading in the 1990s. He founded and led a proprietary trading group that created one of Wall Street's first fully automated global statistical arbitrage strategies, which became one of the most profitable teams at what is now a well-known, leading hedge fund.\n\nThe founding team brings expertise in applied Quantitative Finance, Computer Science, Machine Learning, AI, Mathematics, Networking, Cyber Security, High-Performance Computing, and Large-Scale System DevOps. Together, they are transforming how market insights are generated and delivered."
  },
  {
    question: "Who is it for?",
    answer: "Anyone involved in financial markets:\n• Casual Retail Traders\n• Day Traders\n• Professional Managers\n• Trading Assistants\n• Financial Analysts\n• Media Professionals"
  }
];

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 dark:border-gray-700 last:border-b-0">
      <button
        className="flex justify-between items-center w-full text-left py-5 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-lg transition-all duration-300 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold text-gray-800 dark:text-white pr-4">{question}</span>
        {isOpen ? (
          <ChevronUp className="w-6 h-6 text-green-600 dark:text-green-400 flex-shrink-0" />
        ) : (
          <ChevronDown className="w-6 h-6 text-gray-500 dark:text-gray-400 flex-shrink-0" />
        )}
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="p-4 bg-white dark:bg-gray-800 rounded-b-lg">
          {answer.split('\n').map((paragraph, index) => (
            <p key={index} className="text-gray-600 dark:text-gray-300 leading-relaxed mb-3 last:mb-0">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const FAQSection: React.FC = () => {
  return (
    <section id="faq" className="py-16 md:py-24 bg-gray-100 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        <Parallax translateY={[-10, 10]}>
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800 dark:text-white">Frequently Asked Questions</h2>
        </Parallax>
        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          {faqs.map((faq, index) => (
            <Parallax key={index} translateY={[5, -5]} easing="easeInQuad">
              <FAQItem question={faq.question} answer={faq.answer} />
            </Parallax>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;