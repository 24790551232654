import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Target, Zap, Users, BookOpen, Menu, X, Moon, Sun } from 'lucide-react';
import { useDarkMode } from '../contexts/DarkModeContext';

const menuItems = [
  { href: "/#mission", text: "Mission", icon: Target, color: "#FFA500" },
  { href: "/#features", text: "Features", icon: Zap, color: "#4169E1" },
  { href: "/#use-cases", text: "Use Cases", icon: Users, color: "#FFA500" },
  { href: "/blog", text: "Blog", icon: BookOpen, color: "#228B22" },
];

const Header: React.FC = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode, toggleDarkMode } = useDarkMode();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    const handleScroll = () => {
      if (location.pathname !== '/') return;

      const scrollPosition = window.scrollY;
      const sections = menuItems.map(item => item.href.slice(2));

      const currentSection = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const { offsetTop, offsetHeight } = element;
          return scrollPosition >= offsetTop - 100 && scrollPosition < offsetTop + offsetHeight - 100;
        }
        return false;
      });

      if (currentSection) {
        setActiveSection(currentSection);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    e.preventDefault();
    if (href.startsWith('/#')) {
      if (location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          const element = document.getElementById(href.substring(2));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      } else {
        const element = document.getElementById(href.substring(2));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } else {
      navigate(href);
    }
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-sm fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Link to="/" className="flex items-center space-x-2 group">
            <div className="transition-transform duration-300 ease-in-out transform group-hover:scale-110 group-hover:rotate-3">
              <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 md:w-12 md:h-12">
                <rect x="4" y="4" width="4" height="24" fill="currentColor"/>
                <rect x="12" y="12" width="4" height="20" fill="currentColor"/>
                <rect x="20" y="8" width="4" height="20" fill="currentColor"/>
                <rect x="28" y="0" width="4" height="18" fill="currentColor"/>
              </svg>
            </div>
            <span className="text-2xl md:text-3xl font-bold text-green-600 dark:text-green-400 ml-2 transition-transform duration-300 ease-in-out transform group-hover:scale-105">tradeInsightAI_</span>
          </Link>
        </div>
        {isMobile ? (
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-300"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        ) : (
          <nav>
            <ul className="flex space-x-6 md:space-x-8">
              {menuItems.map((item) => (
                <li key={item.href}>
                  <Link
                    to={item.href}
                    onClick={(e) => handleClick(e, item.href)}
                    className={`text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-300 flex items-center group ${
                      activeSection === item.href.slice(2) || (item.href === '/blog' && location.pathname === '/blog') ? 'text-green-600 dark:text-green-400' : ''
                    }`}
                  >
                    <item.icon className="w-5 h-5 mr-2 transform group-hover:scale-110 transition-transform duration-300" style={{ color: item.color }} />
                    <span className="relative text-base md:text-lg">
                      {item.text}
                      <span className={`absolute left-0 right-0 bottom-0 h-0.5 bg-green-600 dark:bg-green-400 transform transition-transform duration-300 ${
                        activeSection === item.href.slice(2) || (item.href === '/blog' && location.pathname === '/blog') ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                      }`}></span>
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}
        <button
          onClick={toggleDarkMode}
          className="ml-4 p-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-300"
          aria-label="Toggle dark mode"
        >
          {darkMode ? <Sun size={20} /> : <Moon size={20} />}
        </button>
      </div>
      {isMobile && (
        <div
          className={`fixed inset-0 bg-white dark:bg-gray-800 z-40 transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex justify-end p-4">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-300"
            >
              <X size={24} />
            </button>
          </div>
          <nav className="p-4">
            <ul className="space-y-4">
              {menuItems.map((item) => (
                <li key={item.href}>
                  <Link
                    to={item.href}
                    onClick={(e) => handleClick(e, item.href)}
                    className={`text-gray-600 dark:text-gray-300 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-300 flex items-center ${
                      activeSection === item.href.slice(2) || (item.href === '/blog' && location.pathname === '/blog') ? 'text-green-600 dark:text-green-400' : ''
                    }`}
                  >
                    <item.icon className="w-6 h-6 mr-3" style={{ color: item.color }} />
                    <span className="text-xl">{item.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;