import React, { useState } from 'react';
import { FileText, BarChart2, Zap } from 'lucide-react';
import { Parallax } from 'react-scroll-parallax';
import Modal from './Modal';

const features = [
  {
    title: 'Automated Narrative Generation',
    description: 'Transform complex financial data into clear, concise narratives.',
    icon: FileText,
    color: '#FFD700', // Changed to yellow
    details: [
      { title: 'Simplified Insights', description: 'Automatically converts complex high frequency financial data into clear, easy-to-understand narratives using advanced NLP, making it accessible for users at all levels.' },
      { title: 'Customized Reporting', description: 'Delivers reports tailored to specific user needs, free from unnecessary technical jargon, focusing on what\'s relevant and actionable.' },
      { title: 'Multi-Source Integration', description: 'Combines insights from market data, news, and social media sentiment to create comprehensive, contextual narratives.' },
      { title: 'Enhanced Understanding', description: 'Helps users grasp the meaning behind market/auction movements by transforming raw data into informative, plain language stories that facilitate decision-making.' },
    ],
  },
  {
    title: 'Real-Time Analysis',
    description: 'Get up-to-the-minute insights on market trends and opportunities.',
    icon: BarChart2,
    color: '#4169E1', // Royal Blue
    details: [
      { title: 'Continuous Monitoring', description: 'Continuously tracks financial data, news, and social media, ensuring you stay updated with all market-moving events as they unfold.' },
      { title: 'Timely Insights', description: 'Provides real-time analysis to deliver actionable insights exactly when you need them, empowering you to respond quickly and confidently to emerging opportunities and risks.' },
      { title: 'User-Defined Context', description: 'Allows users to select specific time periods for analysis, providing detailed narratives and insights that offer context for market behavior during those times.' },
      { title: 'Market Awareness', description: 'Integrates data from diverse sources to enhance your situational awareness, ensuring you are always informed about market dynamics and key events influencing asset prices.' },
      { title: 'Opportunity Identification', description: 'Helps identify opportunities or risks in real time by processing multiple data streams simultaneously, ensuring you never miss an important market signal.' },
    ],
  },
  {
    title: 'Insight Generation',
    description: 'Uncover hidden patterns and correlations in financial data.',
    icon: Zap,
    color: '#228B22', // Forest Green
    details: [
      { title: 'Auction Dynamics', description: 'Extracts insights from the double auction process to deliver a deep understanding of price formation and market behavior.' },
      { title: 'Market Trends and Performance Analysis', description: 'Analyzes market patterns and evaluates key metrics like returns, risks, and volatility using high-resolution data, providing confident, data-driven insights on asset performance.' },
      { title: 'Context and Economic Indicators', description: 'Integrates data from news, social media, and economic indicators to provide context for market movements and sentiment, helping users understand the broader dynamics at play.' },
    ],
  },
];

const FeaturesSection: React.FC = () => {
  const [modalContent, setModalContent] = useState<{ title: string; content: React.ReactNode; color: string } | null>(null);

  const openModal = (feature: typeof features[0]) => {
    setModalContent({
      title: feature.title,
      content: (
        <div>
          {feature.details.map((detail, index) => (
            <div key={index} className="mb-4">
              <h4 className="font-semibold text-lg">{detail.title}</h4>
              <p className="text-gray-600 dark:text-gray-300">{detail.description}</p>
            </div>
          ))}
        </div>
      ),
      color: feature.color,
    });
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <section id="features" className="py-20 bg-white dark:bg-gray-800">
      <div className="container mx-auto px-4">
        <Parallax translateY={[-10, 10]}>
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800 dark:text-white">Key Features</h2>
        </Parallax>
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <Parallax key={feature.title} translateY={[10, -10]} scale={[0.95, 1.05]} easing="easeInQuad">
              <div
                className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6 transition-all duration-300 hover:shadow-lg transform hover:-translate-y-2 hover:scale-105 perspective-1000 group"
                style={{
                  transformStyle: 'preserve-3d',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div className="transition-transform duration-300 group-hover:rotate-y-10 group-hover:rotate-x-10" style={{ transformStyle: 'preserve-3d' }}>
                  <feature.icon className="w-12 h-12 mb-4 transition-transform duration-300 group-hover:scale-110" style={{ color: feature.color }} />
                  <h3 className="text-xl font-semibold mb-2" style={{ color: feature.color }}>{feature.title}</h3>
                  <p className="text-gray-600 dark:text-gray-300 mb-4">{feature.description}</p>
                  <button
                    onClick={() => openModal(feature)}
                    className="font-semibold focus:outline-none transition-colors duration-300"
                    style={{ color: feature.color }}
                    aria-label={`Learn more about ${feature.title}`}
                  >
                    <div className="animate-dots">
                      {[...Array(6)].map((_, i) => (
                        <span key={i} style={{ backgroundColor: feature.color }}></span>
                      ))}
                    </div>
                  </button>
                </div>
              </div>
            </Parallax>
          ))}
        </div>
      </div>
      {modalContent && (
        <Modal
          isOpen={!!modalContent}
          onClose={closeModal}
          title={modalContent.title}
          content={modalContent.content}
          color={modalContent.color}
        />
      )}
    </section>
  );
};

export default FeaturesSection;